import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { FormattedMessage } from 'react-intl';
import Layout from '../components/Layout';
import Pricing from '../scenes/Pricing';
import HeadMeta from '../components/HeadMeta';

const {
  REACT_COMPANY_NAME,
} = process.env;

const metaData = {
    en: {
        title: `TriDyme | Pricing`,
        pageTitle: "Pricing",
        description: "TriDyme pricing presentation"
    },
    fr: {
        title: `TriDyme | Tarifs`,
        pageTitle:  "Tarifs",
        description: "TriDyme tarfication des abonnements"
    }
};


const PricingPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <HeadMeta 
      locale={locale} 
      metaData={metaData}
    />
    <Pricing />
  </Layout>
)

PricingPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default PricingPage;